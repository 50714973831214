.usageContainer {
    flex: 1;
    padding: 1.5rem;
    padding-left:17.5rem;
    width: 100%;
    min-height: fit-content !important;
}
@media only screen and (max-width: 960px) {
    .usageContainer {
        padding: 1.5rem 1rem;
        padding-left:1rem;
    }
  }