.personaNav {
  display: flex;
  flex-direction: column;
}

.notallowed-link {
  width: fit-content;
}

.notallowed-item {
  padding: 0.75rem;
  color: var(--primary-color);
  font-weight: 600;
  font-size: 0.85rem;
  line-height: 1rem;
  width: fit-content;
  display: block;
}

.personaNav-item {
  text-decoration: none;
}

.personaNav-item .item {
  margin-bottom: 0.25rem;
  padding: .5rem 1rem;
  border-radius: 4px;
  font-weight: 500;
  font-size: 1rem;
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
}

.personaNav-item .item:hover {
  background: rgba(243, 244, 245, 0.3);
  color: var(--text-color);
}

.personaNav-item .item:focus,
.personaNav-item .item.current {
  background: rgba(243, 244, 245, 1);
  color: var(--text-color);
}

.personaNav-item .item svg {
  width: 1rem !important;
}

.admin-item {
  padding: 0.75rem;
  color: var(--primary-color);
  font-weight: 600;
  font-size: 1rem;
  line-height: 1rem;
  border: 1px solid #fff;
  border-radius: 0.375rem;
}

.admin-item:hover,
.admin-item:focus,
.admin-item.current {
  border: 1px solid var(--primary-color);
}




/* sideNavDrawer TEMPORARY */

#sideNavDrawer,
#sideNavDrawer-toggle-label {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  /* adds animation for all transitions */
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
  margin: 0;
  padding: 0;
  -webkit-text-size-adjust: none;
}


#sideNavDrawer-toggle {
  position: absolute;
  opacity: 0;
  z-index: 9999
}

#sideNavDrawer-toggle-label {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  left: 0px;
  height: 4rem;
  width: 3rem;
  display: block;
  position: fixed;
  background: rgba(255, 255, 255, .0);
  z-index: 1060;
  top: 0;
  cursor: pointer;
}

@media only screen and (min-width: 960px) {
  #sideNavDrawer-toggle-label {
    display: none;
  }
}

/* adds our "hamburger" menu icon */

#sideNavDrawer-toggle-label:before {
  content: '';
  display: block;
  position: absolute;
  height: 2px;
  width: 20px;
  background: #333;
  left: 13px;
  top: 20px;
  box-shadow: 0 6px 0 #333, 0 12px 0 #333;
}

/* sideNavDrawer menu pane - note the 0px width */

#sideNavDrawer {
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 4rem);
  flex: 0 0 16rem;
  width: 16rem;
  padding: 1rem;
  border-right: 1px solid var(--border-color);
  left:0;
  top:4rem;
}

@media only screen and (max-width: 960px) {
  #sideNavDrawer {
    position: fixed;
    z-index: 999;
    top: 0;
    left: -16rem;
    height: 100vh;
  }
}

/* checked styles (menu open state) */

#sideNavDrawer-toggle:checked~#sideNavDrawer-toggle-label {
  height: 100%;
  width: calc(100% - 16rem);
  background: rgba(255, 255, 255, .8);
}

#sideNavDrawer-toggle:checked~#sideNavDrawer-toggle-label {
  left: 16rem;
}

#sideNavDrawer-toggle:checked~#sideNavDrawer {
  left: 0;
}