.right-container {
  padding-inline: 0;
  padding-block: 0;
}

.upload-button {
  background-color: var(--primary-color);
  width: 37px;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
}

.upload-button svg {
  width: 80%;
}
.orange-text{
  background-color: rgba(244, 119, 28, 0.20);
}
.text-start {
  display: flex;
  align-items: center;
  height: 3rem;
  font-size: 1.3rem;
  /* margin-top: 1rem; */
  margin-inline: 1.3rem;
  margin-bottom: 0;
}

.search-container {
  background: var(--light-grey);
  padding: 1rem 1.5rem;
  display: flex;
  align-items: center;
}

@media (max-width: 960px) {
  .search-container {
    padding: 1rem;
  }
}

.empty-resource {
  background: rgba(71, 117, 213, 0.05);
  border-radius: 0.571rem;
  width: 100%;
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-block: 1.5rem;
}

.pageHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: 1.5rem;
}

.selected-resources-container {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.selected-resources {
  /* width: 50%; */
  display: flex;
  border-radius: 4px;
  border: 1px solid #4775d5;
  padding: 0.357rem 0px 0.357rem 0.357rem;
  align-items: center;
  position: fixed;
  bottom: 1rem;
  background: #fff;
  z-index: 1000
}

.file-name {
  padding-top: 1px;
  /* text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; */
}

.resources-count {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  width: 44px;
  height: 44px;
  background: #4775d5;
}

.resources-count h4 {
  color: white;
  font-weight: 400;
  font-size: 19px;
  margin-bottom: 0;
}

.resources-text {
  color: var(--primary-color);
  font-weight: 500;
  margin-bottom: 0;
  font-family: 'Inter Tight', sans-serif;
}

.icons-container {
  margin-left: 1.5rem;
  border-right: 1px solid #ced3d9;
  padding-right: 1em;
}

.action-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  font-size: 0.875rem;
}

.action-item:hover {
  opacity: 0.8
}

.action-item p {
  margin-bottom: 0;
}

.resource-type {
  color: #76828b;
  font-weight: 300;
  font-size: .875rem;
  line-height: 1em;
}

.file-name {
  margin-left: 0 !important;
}

.clearSearch {
  height: 2.5rem !important;
  width: 2.5rem !important;
  position: absolute !important;
  right: 2.25rem !important;
  padding: .5rem !important;
  cursor: pointer !important;
  top: 1px !important;
  background: transparent !important;
}

.clearSearch:disabled {
  display: none !important;
}