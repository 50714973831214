.preview-header-text {
  font-size: 0.9rem;
  color: #111720;
  letter-spacing: 2px;
  font-weight: 500;
}

.preview-header-section {
  border-radius: 0.8rem;
  border: 1px solid #ddd;
  background: #fff;
  width: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.preview-persona-info img {
  width: 130px;
  height: 130px;
  border-radius: 50%;
}
.preview-persona-name {
  color: #051d2c;
  font-size: 1.3rem;
  font-weight: 500;
  padding-top: 0.8rem;
  margin-bottom: 0;
}
.preview-persona-company {
  color: #111720;
  font-size: 0.85rem;
  font-weight: 400;
}

.leadership-btn {
  display: flex;
  padding: var(--1, 8px) 12px;
  justify-content: center;
  align-items: center;
  gap: var(--1, 8px);
  border-radius: 100px;
  background: #1e8e5c;
}

.leadership-btn p {
  margin-bottom: 0;
  color: white;
}
.preview-header-lower-section {
  border-radius: 0.8rem;
  border: 1px solid #ddd;
  background: #fff;
  width: 100%;
  padding-block: 2rem;
  padding-right: 2rem;
  display: flex;
  flex-direction: column;
}
.who-is-heading {
  background: #1e8e5c;
  width: 0.357rem;
  height: 2.7rem;
}
.campaign-heading {
  background: #3a3f4b;
  width: fit-content;
  height: 2.7rem;
  padding-inline: 1rem;
  font-family: monospace;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.who-is-upper-para {
  margin-top: 1.5rem;
  color: #212529;
  font-size: 1.1rem;
  font-weight: 400;
  /* word-spacing: 6px; */
  font-family: monospace;
}

.who-is-lower-para {
  color: #212529;
  font-size: 0.9rem;
  font-weight: 350;
  line-height: 20px;
}

.audience-section {
  min-height: 400px;
  position: relative;
  border-radius: 0.8rem;
  border: 1px solid #ddd;
  background: #fff;
  width: 100%;
  padding-block: 2rem;
  padding-right: 2rem;
  display: flex;
  flex-direction: column;
}

.campaign-title {
  color: #051d2c;
  font-size: 1.5rem;
  font-weight: 400;
  padding-left: 2.357rem;
  margin-top: 1.7rem;
  margin-bottom: 0.3rem;
}
.campaign-bullet-heading {
  color: #212529;
  font-family: monospace;
  font-size: 1.15rem;
  font-weight: 500;
  margin-bottom: 0.4rem;
}

@media (max-width: 1399px) {
}

@media (max-width: 1199px) {
  .preview-persona-info img {
    width: 110px;
    height: 110px;
  }
}

@media (max-width: 767px) {
  .preview-header-text{
    font-size: 0.9rem;
  }
  .preview-persona-info img {
    width: 100px;
    height: 100px;
  }
  .preview-persona-name{
    font-size: 1rem;
  }
  .preview-persona-company{
    font-size: 0.75rem;
  }
  .leadership-btn {
    display: flex;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 100px;
    background: #1e8e5c;
    font-size: 0.95rem;
    white-space: nowrap;
  }
  .who-is-upper-para {
    font-size: 0.95rem;
    margin-top: 0.8rem;
  }
  .who-is-lower-para{
    font-size:0.85rem ;
  }
}
