.signupContainer {
  min-height: 100vh;
  width: 100%;
  display: flex;
  padding-block: 1.5rem;
  justify-content: center;
  align-items: center;
  background: #f6f6f6;
}

.signupContainer-inner {
  max-width: 40rem;
  width: 100%;
  padding-inline: 3rem;
  padding-block: 1rem;
  background: #fff;
  box-shadow: 0 0.857rem 40px rgba(0, 0, 0, 0.12);
}

.signupContainer-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signupContainer-header h1 {
  text-align: center;
  font-size: 1.5rem;
  margin-top: 1.5rem;
  font-weight: 600;
}

.logo {
  width: 4.5rem;
  height: 4.5rem;
}
.error {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
  line-height: 2px !important;
}
.create-account-btn {
    background: #051D2C !important;
    color: white;
    font-weight: 400 !important;
    /* width: 100%; */
  }
.signupContainer-form p {
  text-align: center;
}

.form-control {
  width: 100%;
}

.signup-link {
  color: #051D2C;
  font-weight: 600;
}
.signup-link:hover {
  cursor: pointer;
}
@media only screen and (max-width: 44rem) {
  .signupContainer {
    background: #fff;
  }

  .signupContainer-inner {
    padding: 1rem;
  }
}
