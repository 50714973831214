
@import url("https://use.typekit.net/ceh2jwg.css");

.Container-right {
  font-family: var(--primary-font);
    flex: 1;
    max-width: 100%;
    overflow: hidden;
    padding-bottom: 10px;
}
.highlight {
    background-color: #D3F8E6; /* You can adjust the highlighting color */
    padding: 1px;
    color: #239761;
  }
  .show-on-desktop {
    display: none !important;
  }
  
  @media only screen and (min-width:960px) {
    .show-on-desktop {
      display: flex !important;
    }
  }