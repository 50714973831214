.signupContainer {
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f6f6f6;
}

.signupContainer-inner {
  max-width: 40rem;
  width: 100%;
  padding-inline: 3rem;
  padding-block: 1rem;
  background: #fff;
  box-shadow: 0 0.857rem 40px rgba(0, 0, 0, 0.12);
}

.create-account-btn {
  background: #051D2C !important;
  color: white;
  font-weight: 400 !important;
  /* width: 100%; */
}

.signupContainer-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-block: 2rem
}

.signupContainer-header h1 {
  text-align: center;
  font-size: 1.5rem;
  margin-top: 1.5rem;
  font-weight: 600
}

.password-field {
  position: relative;
}

.logo {
  width: 8rem;
  /* height: 4.5rem; */
}

.signupContainer-form {}

.signupContainer-form p {
  text-align: center;
}

.form-control {
  width: 100%
}

.signup-link {
  color: #051D2C;
  font-weight: 600;
}

.signup-link:hover {
  cursor: pointer;
}

.login-title {
  color: #051D2C;
  /* font-family: Normalidad; */
  font-size: 1.429rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2.071rem;
  /* 131.818% */
  margin-bottom: 2.143rem;
  text-align: center;
}

.forget-text {
  color: #051D2C;
  font-size: 1.071rem;
  font-style: normal;
  font-weight: 350;
  line-height: 1.714rem;
  /* 160% */
}

.login-blue-text {
  color: #4775D5 !important;
  font-size: 1.071rem;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 1.714rem;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;

}

@media only screen and (max-width: 44rem) {
  .signupContainer {
    background: #fff;
  }

  .signupContainer-inner {
    padding: 1rem;
  }
}