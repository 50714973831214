.moments-table {
  font-family: var(--primary-font) !important;
}
.mind-row {
  border-bottom: 2px solid var(--primary-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mind-row > div {
  padding: 1rem;
  padding-left: 0;
}

.mind-row .cursor-pointer img {
  width: 1rem;
}

.mindContainer {
  max-width: 100%;
}

.mindContainer h1 {
  text-align: left;
  margin-bottom: 1rem;
  margin-top: 0;
}
.show-on-desktop {
  display: none !important;
}

@media only screen and (min-width: 960px) {
  .show-on-desktop {
    display: flex !important;
  }
}

.mind-page .female-img {
  width: 33px;
  height: 33px;
}
.mind-page .edit-img {
  width: 1.286rem;
  height: 1.286rem;
}


.progress-value {
  font-weight: 600 !important;
  font-size: 0.7rem !important;
  letter-spacing:-1px;
  color: #051D2C !important;
}
.file-name {
  font-weight: 600 !important;
  font-size: 1rem !important;
  margin-left: 10px !important;
}

.highlight-text {
  /* color: #4775D5; */
  background-color: rgba(71, 117, 213, 0.20);
}

