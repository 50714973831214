.clearSearch {
    height: 2.5rem !important;
    width: 2.5rem !important;
    position: absolute !important;
    right: 2.25rem !important;
    padding: .5rem !important;
    cursor: pointer !important;
    top: 1px !important;
    background: transparent !important;
}

.clearSearch:disabled {
    display: none !important;
}