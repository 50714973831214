.mainHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 var(--spacing-medium);
  height: 4rem;
  width: 100%;
  background: #fff;
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  border-bottom: 1px solid var(--border-color);
}

.mainHeader .dropdown-toggle::after {
  display: none;
}

.emissaryLogo {
  width: 2rem;
  height: 2rem;
}

.brook-img {
  width: 2rem;
  height: 2rem;
}

.hideToggle {
  display: none;
}

.user-name {
  font-weight: 600;
  /* color: white; */
}

/* toggler css */
.checkbox {
  opacity: 0;
  position: absolute;
}

.label:hover {
  cursor: pointer;
}

.label {
  width: 50px;
  height: 23px;
  background-color: #0071bc;
  display: flex;
  border-radius: 50px;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  position: relative;
  transform: scale(1.4);
}

.ball {
  width: 1.286rem;
  height: 1.286rem;
  background-color: white;
  position: absolute;
  top: 2px;
  left: 2px;
  border-radius: 50%;
  transition: transform 0.2s linear;
}

/*  target the elemenent after the label*/
.checkbox:checked+.label .ball {
  transform: translateX(1.714rem);
}

.fa-moon {
  color: white;
}

.fa-sun {
  color: white;
  font-size: 0.857rem;
}

/* ligght theme */
.app-navbar-light {
  background-color: black;
}

.persona-link-light {
  color: black
}

.header-username-light {
  color: black
}

.header-username-light:hover {
  color: black;
}

/* dark theme */
.app-navbar-dark {

  background-color: #F9F9F9;
}

.persona-link-dark {
  color: white
}

.header-username-dark {
  color: white
}

.header-username-dark:hover {
  color: white;
}

@media only screen and (max-width: 960px) {
  .mainHeader {
    padding-left:4rem;
  }
}