/********************************
GRID
********************************/

.emissary-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 1.75rem;
  column-gap: 1.75rem;
}

@media only screen and (max-width: 1300px) {
  .emissary-grid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media only screen and (max-width: 800px) {
  .emissary-grid {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 500px) {
  .emissary-grid {
    grid-template-columns: 1fr;
  }
}

/********************************
CARDS
********************************/

.emissary-card {
  position: relative;
  border-radius: 0.571rem;
  border: 1px solid #ced3d9;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.emissary-card:hover {
  box-shadow: 0 12px 40px rgba(0, 0, 0, .12);

}

.upper-card {
  padding: 1.714rem;
  position: relative;
}

.persona-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* gap: 0.857rem; */
  /* align-self: stretch; */
}

.persona-action-buttons {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
  top: 1em;
  right: 1rem;
}

.persona-name {
  display: flex;
  flex-direction: column;
  gap: 1px;
  align-items: center;
  margin-top: 10px;
}

.persona-name h3 {
  color: var(--primary-color);
  text-align: center;
  font-size: 1.375rem;
  line-height: 1.3em;
  margin-bottom: 0;
}

.persona-name p {
  color: #76828B;
  text-align: center;
  font-size: 0.929rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5em;
  min-width: fit-content;
}

.social-links {
  /* align-self: center;
  bottom: 10px;
  position: absolute; */
  display: flex;
  align-items: center;
  gap: 23px;
  justify-content: center;
}

.emissary-cardHeader {
  display: flex;
  justify-content: space-between;
}

.dropdown-toggle::after {
  display: none;
}

.emissary-cardHeader .icon {
  width: 2.25rem;
  height: 2.25rem;
  background: pink;
}

.emissary-cardHeader i {
  font-size: 1.25rem;
}

.emissary-cardHeader .avatar {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
}

.lower-card {
  border-top: 1px solid #ced3d9;
  background: #f9f9f9;
  padding: 1.714rem;
  border-bottom-right-radius: 0.571rem;
  border-bottom-left-radius: 0.571rem;
  flex-grow: 1;
}

.persona-interest h3,
.persona-projects h3 {
  color: #76828b;
  font-size: 0.857rem;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.persona-interest p {
  color: #111720;
  font-style: normal;
  font-weight: 400;
}

.project-names-container {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  align-items: center;
  width: 100%;
}

.projectname {
  border-radius: 50px;
  border: 1px solid #ced3d9;
  background: #fff;
  padding: 0.286rem 0.857rem;
  display: flex;
  font-size: 0.875rem;
  align-items: center;
  justify-content: center;
  min-width: fit-content;
  cursor: pointer;
  word-break: break-all;
}

.projectname:hover {
  background: var(--primary-color);
  color: #fff;
  border: 1px solid var(--primary-color);
}

.avatar:hover {
  cursor: pointer;
}

.emissary-cardHeader .avatar img {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
}

.emissary-cardBody {
  padding: var(--spacing-small) 0 0 0;
}

.emissary-cardBody h2 {
  width: fit-content;
  font-size: 1.25rem;
  font-weight: 600;
  cursor: pointer;
  margin-bottom: 0.25rem;
}

.emissary-cardBody h2:hover {
  opacity: 0.8;
}

.emissary-cardBody p {
  margin-bottom: 0;
}

.user-email {
  margin-top: 1rem;
}

.emissary-cardFooter {
  border-top: 1px solid #cacaca;
  padding-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.emissary-cardFooter img {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
}

/********************************
BUTTONS
********************************/
.divider {
  height: 1px;
  width: 100%;
  background: #cacaca;
  margin: var(--spacing-medium) 0;
}

.emissary-container .left-column .btn-primary {
  width: 100%;
}

.persona-container {
  max-width: 100%;
  margin: 0 auto;
  padding: var(--spacing-medium);
}

@media only screen and (max-width: 767px) {
  .persona-container {
    padding: 1.5rem 1rem;
  }
}

.create-persona-link {
  border: 2px solid #051D2C;
  width: 200px;
  height: 50px;
}

.create-persona-link:hover {
  color: #051D2C;
}

.all-personna-div h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
}

.all-persona-btn {
  border: 2px solid #051D2C;
  margin: 50px 0px 0px 500px;
  width: 20%;
  height: 40px;
}

.all-persona-btn h5 {
  margin-top: 10px;
  margin-left: 30px;
}

.persona-text-container p {
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  max-width: 641px;
}

.create-btn-container {
  border: 1px solid var(--inputBorder-color);
  border-radius: 5px;
}

.create-btn-container a {
  font-style: normal;
  font-weight: 500;
}

.all-personna-div input:focus {
  outline: none;
}

.filter {
  height: 40px;
  background: #ffffff;
  border: 1px solid var(--inputBorder-color);
  border-radius: 5px;
}



.persona-table-head {
  font-style: normal;
  font-weight: 600;
  font-size: 0.857rem;
  line-height: 1rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--inputBorder-color);
}

.persona-table-head tr {
  border-style: hidden;
}

.persona-table-head th {
  text-align: left;
}

.persona-table-body tr {
  height: 72px;
  width: 500p;
}

.persona-table-body td {
  vertical-align: middle;
  text-align: left;
}

.persona-table-body .company-td {
  font-weight: 400;
}

.persona-name {
  font-weight: 500;
  font-size: 1em;
  line-height: 1em;
  color: var(--inputBorder-color);
}

.search-img {
  width: 1.286rem;
  height: 1.286rem;
}

.personaAvatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.female {
  width: 40px;
  height: 40px;
}

.persona-dot-options::marker {
  content: "";
}

.persona-dot-options a::after {
  display: none;
}

.persona-dot-options .nav-dropdown {
  bottom: 0;
}

.profile-image {
  width: 200px;
  height: 200px;
}

.profile-image:hover {
  cursor: pointer;
}

#file-upload,
#img-upload {
  display: none;
}

.swal2-popup {
  z-index: 9999;
  /* Set a higher z-index value to make SweetAlert2 popups appear above other elements */
}

.swal2-container {
  z-index: 1500 !important;
}

/* Persona actions buttons */
.persona-action-buttons {
  display: flex;
  flex-direction: column;
  gap: 0.857rem;
  align-items: center;
}

.persona-action-buttons .personaCardButton {
  cursor: pointer;
  position: relative;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.persona-action-buttons .personaCardButton svg {
  position: relative;
  z-index: 11;
}

.persona-action-buttons .personaCardButton::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.persona-action-buttons .personaCardButton:hover::after {
  transform: scale(1.5);
  opacity: 0;
  background: rgba(243, 244, 245, 1);
  transition: transform .5s, opacity .5s;
}

.persona-action-buttons .personaCardButton::after {

  border-radius: 50%;
}


.search-input {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #76828B;
  color: #051D2C;
  font-size: 1rem;
  gap: 0.5rem !important;
  height: 2.5rem;
  background: #ffffff;
}