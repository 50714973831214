.form__group.custom-text-field {
    position: relative;
    /* padding: 15px 0 0;
    margin-top: 10px; */
  }
  
  .custom-text-field .form__field {
    font-family: inherit;
    width: 100%;
    border: 0;
    border: 1px solid #CED3D9;
    border-radius: 4px;
    outline: 0;
    font-size: 0.929rem;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 215.385% */
    color: #212121;
    padding: 8.5px 12px;
    background: transparent;
    transition: border-color 0.2s;
  }
  .custom-text-field .form__field:hover{
    border-color:#051D2C ;
  }
  .custom-text-field .form__field::placeholder {
    color: transparent;
  }
  
  .custom-text-field .form__field:placeholder-shown ~ .form__label {
    font-size: 1.143rem;
    cursor: text;
    top: 10px;
  }
  
 .custom-text-field label,
  .custom-text-field .form__field:focus ~ .form__label {
    position: absolute;
    top: -10px;
    left:10px;
    display: block;
    transition: 0.2s;
    font-size: 0.857rem;
    color: #9b9b9b;
    background:white;
    padding: 0 5px;
  }
  
  .custom-text-field .form__field:focus ~ .form__label {
    color: rgb(25, 118, 210);
  }
  
  .custom-text-field .form__field:focus {
    /* padding-bottom: 6px; */
    border: 1px solid rgb(25, 118, 210);
  }

  .custom-text-field .error-message{
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin: 0.286rem 1rem 0rem;
    color: #dc3545;
  }