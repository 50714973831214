.edit-fascimile-link-btn img {
    width: 30px;
    height: 30px;
    margin-left: 550px;
    margin-top: -250px;
  }
  .edit-fascimile-approved-btn img {
    width: 30px;
    height: 30px;
    margin-left: 110px;
    margin-top: -250px;
  }
  .delete-btn {
    width: 30px;
    height: 30px;
  }
  .edit-fascimile-btn{
  margin-right: 300px;
}