.pdf-header-text {
  letter-spacing: 2px;
  font-weight: 500;
  font-size: 0.8rem;
  margin-bottom: 3rem;
}
.pdf-username {
  font-size: 2.3rem;
  line-height: 1.3;
  font-family: monospace !important;
  font-weight: 400 !important;
  color: #111720;
  max-width: 17rem;
  margin-bottom: 2rem;
}
.pdf-leadership-btn {
  /* Centering Text Vertically */
  display: table-cell;
  vertical-align: middle;

  /* Making sure the div acts as a table cell */
  width: fit-content; /* Example fixed width, it should be set to ensure proper centering */
  text-align: center; /* Centering Text Horizontally */
  text-align: center;
  padding: 0px 12px;
  background: #1e8e5c;
  font-size: 0.85rem;
  color: white;
  text-transform: uppercase;
  margin-bottom: 2rem;
}
.ma-black {
  width: 2.2rem;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #111720;
}
.black-right-div {
  width: 4rem;
  height: calc(100vh - 18rem);
  background-color: #111720;
  margin-block: 6rem;
}

.profile-section {
  width: fit-content;
  margin: 0 auto;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.pdf-info-container {
  width: 100%;
  margin-bottom: 0.5rem;
}
.pdf-info-title {
  width: 30%;
  color: #929da5;
  font-family: monospace;
  font-size: 0.95rem;
  font-weight: 500;
}
.pdf-info-desc {
  width: 70%;
  color: #111720;
  font-family: monospace;
  font-size: 0.95rem;
  font-weight: 500;
  margin-left: 3rem;
}
.pdf-info-title,
.pdf-info-desc {
  display: inline; /* Display divs inline */
  box-sizing: border-box; /* Include padding in width */
}
