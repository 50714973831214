.momentContainer {
  max-width: 60rem;
  width: 100%;
}

.momentUploads {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.avatar {
  width: 2.25rem;
  height: 2.25rem;
}

.user-input-div {
  border: 2px solid var(--primary-color);
  padding: .75rem;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
  border-radius: 0.375rem;
}
.user-input-div span {
  margin-top: 7px;
}
.user-input-div:hover {
  background: var(--primary-color);
  color: #fff;
}


.user-input-div img {
  height: 1.75rem;
  width: auto;
  margin-bottom: 0.4rem;
}

.disable-resource-div {
  opacity: 0.6;
  background: #eee;
  border: 2px solid #eee;
}

.disable-resource-div:hover {
  cursor: not-allowed;
  background: #eee;
  color: var(--primary-color)
}

.momentForm {
  display: Flex;
}

.momentForm .form-field {
  flex: 1;
  padding-right: 1rem;
  margin-bottom: 0;
}

.resourceContainer {
  margin-top: 0rem;
}

.resourceContainer h2 {
  font-size: 1.125rem;
  text-align: center;
  margin: 2rem 0 2rem;
}

.momentResource-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  row-gap: 1rem;
  column-gap: 1rem;
}

@media only screen and (max-width:960px) {
  .momentResource-container {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media only screen and (max-width:700px) {
  .momentResource-container {
    grid-template-columns: 1fr 1fr;
  }
}


.modal-upload-div {
  padding: 2rem;
  border-radius: 0.25rem;
  border: 1px dashed #ccc;
  background: #f6f6f6
}

.modal-upload-div:hover {
  cursor: pointer;
}
.show-on-desktop {
  display: none !important;
}

@media only screen and (min-width:960px) {
  .show-on-desktop {
    display: flex !important;
  }
}
.moments-name {
  font-weight: 500;
  font-size: 1em;
  /* line-height: 1em; */
  color: var(--inputBorder-color);
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  max-width:300px; 
  width: 300px; 
}
.create-moment-btn {
  background: black !important;
  color: white;
  font-weight: 400 !important;
  /* width: 100%; */
}
