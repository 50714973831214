

/********************************
SOCIAL GRID
********************************/

.socialMediaFields {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	row-gap: 0.5rem;
	column-gap: 1rem;
}
@media only screen and (max-width:800px) {
	.socialMediaFields {
		grid-template-columns: 1fr 1fr;
	}
}
@media only screen and (max-width:500px) {
	.socialMediaFields {
		grid-template-columns: 1fr;
	}
}


.box-header {
    font-style: normal;
    font-weight: 500;
    line-height: 1.143rem;
    border-bottom: 1px solid var(--border-color);
}

.dropdownMenuButton {
    border: 1px solid var(--inputBorder-color);
    border-radius: 5px;
    width: 190px;
    height: 45px;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 17px;
    color: var(--inputBorder-color);
}

.global-rules-input {
    border: 1px solid var(--inputBorder-color);
    border-radius: 5px;
    height: 45px;
    width: 240px;
}

.createRuleBtn {
    background: var(--inputBorder-color);
    border: 1px solid var(--inputBorder-color);
    border-radius: 5px;
    color: var(--bgLight-color);
    height: 45px;
}

.createRuleBtn:hover {
    color: var(--bgLight-color);
}

.personal-details .paragraph-text {
    border: 1px solid #3C3C46;
    ;
    border-radius: 5px;
}

.personal-details .edit-div input {
    height: 45px;
    background: var(--bgLight-color);
    border: 1px solid var(--inputBorder-color);
    border-radius: 5px;
}

.personal-details .edit-div span {
    font-weight: 500;
}

.personal-details .footer-div {
    border-top: 1px solid var(--border-color);
}

.personal-details .footer-cancel-btn {
    width: 127px;
    height: 45px;
    border: 1px solid var(--inputBorder-color);
    border-radius: 5px;
}

.personal-details .footer-update-btn {
    width: 127px;
    height: 45px;
    color: var(--bgLight-color);
    background: var(--inputBorder-color);
    border: 1px solid var(--inputBorder-color);
    border-radius: 5px;
}

.company-details .paragraph-text {
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    line-height: 25px;

    color: #3C3C46;
}

.company-details .edit-img {
    height: 1.286rem;
}

.company-details .paragraph-edit-text {
    font-style: normal;
    font-weight: 400;
    font-size: 1em;
    line-height: 25px;
    color: #3C3C46;
    border: 1px solid var(--inputBorder-color);
    border-radius: 5px;
}

.company-details .edit-div input {
    height: 45px;
    background: var(--bgLight-color);
    border: 1px solid var(--inputBorder-color);
    border-radius: 5px;
}

.company-details .input-text {
    font-style: normal;
    font-weight: 500;
    font-size: 1em;
    line-height: 1em;
    color: var(--inputBorder-color);
}


.company-details .footer-div {
    border-top: 1px solid var(--border-color);
}

.company-details .footer-cancel-btn {
    width: 127px;
    height: 45px;
    border: 1px solid var(--inputBorder-color);
    border-radius: 5px;
}

.company-details .footer-update-btn {
    width: 127px;
    height: 45px;
    color: var(--bgLight-color);
    background: var(--inputBorder-color);
    border: 1px solid var(--inputBorder-color);
    border-radius: 5px;
}

.company-details .link-span {
    font-weight: 500;
}


/* Persona Tab CSS*/
.persona-tab {
    width: 60%;
}
.persona-tab .not-edit-div div span {
    font-style: normal;
    font-weight: 400;
    line-height: 1.143rem;
    /* identical to box height, or 100% */


    /* Dark Grey */

    color: var(--inputBorder-color);
}

.persona-tab .not-edit-div .left-text {
    font-style: normal;
    font-weight: 500;
    line-height: 1em;
    /* identical to box height, or 100% */


    /* Dark Grey */

    color: var(--inputBorder-color);
}


.persona-tab .edit-div input {
    height: 45px;
    background: var(--bgLight-color);
    border: 1.5px solid var(--inputBorder-color);
    border-radius: 5px;
}

.persona-tab .edit-div .persona-input {
    font-weight: 500;
}

.persona-tab .footer-div {
    /* border-top: 1px solid var(--border-color); */
}

.persona-tab .footer-cancel-btn {
    width: 127px;
    height: 45px;
    border: 1px solid var(--inputBorder-color);
    border-radius: 5px;
}

.persona-tab .footer-update-btn {
    width: 127px;
    height: 45px;
    color: var(--bgLight-color);
    background: var(--inputBorder-color);
    border: 1px solid var(--inputBorder-color);
    border-radius: 5px;
}

.female-img {
    width: 27px;
    height: 27px;
}

.global-rules-tab .edit-img {
    width: 1rem;
    height: 1rem;
}

.global-rules-tab .cross-img {
    width: 1rem;
    height: 1rem;
}

.global-rules-tab .checkbox {
    width: 1.714rem;
    height: 1.714rem;
}

.personal-details .edit-img {
    width: 1rem;
    height: 1rem;
}

.social-img {
    width: 40%;
}

.cursor-not-allowed {
    cursor: not-allowed;
}

.personal-details .social-img img {
    width: 1.5rem;
    height: 1.5rem;
}


.company-details .edit-img {
    width: 1.286rem;
    height: 1.286rem;
}

.persona-tab .edit-img {
    width: 1rem;
    height: 1rem;
}

.persona-profile-header {
    max-width:60rem;
    width:100%;
    display:flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom:1.5rem;
}
.persona-profile-header p {
    color:var(--darker-grey);
    font-size:0.867rem;
}
.persona-profile-tab{
    max-width:60rem;
    width:100%;
    display:flex;
    justify-content: space-between;
    margin:1rem 0;
    flex-direction:column;
}
.tab-names .chakra-tabs__tablist {
    position: relative
}
.tab-names .chakra-tabs__tablist:after {
    content:"";
    width:100%;
    height:1px;
    background:#ddd;
    position:absolute;
    bottom:-1px;
}

@media only screen and (max-width:101.714rem) {
    .persona-tab {
        width: 100%;
    }
    .persona-profile-tab{
        width: 100%;
    }
  }


  .form-container {
    display:flex;
    flex-direction: column;
    margin-top:2rem;
    width:100%;
  }