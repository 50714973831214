.emissaryChat {
  display: flex;
  min-height: calc(100vh - 4rem);
}

.emissaryChat-list {
  flex: 0 0 16rem;
  border-right: 2px solid var(--primary-color);
}

.emissaryChat-chatbox {
  flex: 1;
  height: calc(100vh - 4rem);
  /* background: #f5f6f7; */
  display: flex;
  flex-direction: column;
  position: relative;
}

.mb-30 {
  margin-bottom: 2rem;
}

/********************************
CHAT HEADER
********************************/
.chatHeader {
  align-items: center;
  background: #f5f6f7;
}

.chatHeader-inner {
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #f5f6f7;
}

@media only screen and (max-width: 767px) {
  .chatHeader-inner {
    padding: 1rem;

  }
}

/********************************
CHIPS CONTAINER
********************************/
.chips-container-outer {
  width: calc(100vw - 16rem);
  display: flex;
  overflow: auto;
  background-color: var(--light-grey);
}

.chips-container {
  background-color: var(--light-grey);
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  grid-gap: 4px;
  padding: 0 1.5rem;
}

@media only screen and (max-width: 960px) {
  .chips-container {
    padding: 0 1rem;
  }
}

.chips-container .MuiChip-sizeMedium {
  flex: 0 0 auto;
  margin: 0 0 1rem 0;
}

@media only screen and (max-width: 960px) {
  .chips-container-outer {
    width: 100vw;
  }
}

/********************************
CHAT INPUT
********************************/
.chat-input {
  background: #fff;
  position: fixed;
  bottom: 0;
  width: calc(100% - 16rem);
  left: 16rem;
}

@media only screen and (max-width: 960px) {
  .chat-input {
    width: 100%;
    left: 0;
  }
}

.chatInput-inner {
  position: relative;
  max-width: 56rem;
  /* max-height: 10rem; */
  margin: 0 auto;
  padding-block: 1rem;
  padding-inline: 1rem;
  display: Flex;
  width: 100%;
}

@media only screen and (max-width: 960px) {
  .chatInput-inner {
    max-width: 100%;
    margin: 0 auto;
    padding-block: 0;
    padding-inline: 0;
  }
}

.chat-question-container {
  background-color: var(--primary-color);
  border-radius: 0.357rem;
  margin-top: .25rem;
  padding: .75rem 1rem;
  display: inline-flex;
  flex-direction: column;
  font-size: 1rem;
  color: white;
  width: 100%;
}

.chat-answer-container {
  background-color: #f5f6f7;
  border-radius: 0.357rem;
  margin-top: .25rem;
  padding: .75rem 1rem;
  display: inline-flex;
  flex-direction: column;
  font-size: 1rem;
  color: #051d2c;
  font-size: 1rem;
  width: 100%;
  color: var(--primary-color);
}

.upload-button {
  background-color: var(--primary-color);
  width: 37px;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.286rem;
  cursor: pointer;
}

.upload-button svg {
  width: 80%;
}

.icon-button {
  background-color: #4775d5;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 0.286rem;
  cursor: pointer;
  padding-inline: 0.857rem;
  padding-block: 10px;
}

.save-button {
  background-color: transparent;
  border: 1px solid #4775d5;
  flex: 0 0 38px;
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.286rem;
  cursor: pointer;
}

/********************************
PROJECT ITEMS
********************************/
.projects-heading {
  color: #76828b;
  font-size: 1rem;
  line-height: 1rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 500;
  /* padding-inline: 0.857rem; */
  padding-block: 10px;
  margin-bottom: 0;
}

.project-container {
  /* height: 28vh; */
  /* max-height: 25vh; */
  margin-bottom: 1rem;
  /* overflow-y: auto; */
  overflow-x: hidden;
}
.custom-link:hover {
  color: #212529; /* Change color on hover */
}
.project-item {
  padding: .5rem 0.857rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.project-item span {
  color: var(--primary-color);
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  /* line-height: 1rem; */
  padding-left: 0.5rem;
}

.modal-content {
  border: none;
}

/********************************
PERSONA CHAT ACTIONS
********************************/
.chats-container {
  height: 63vh;
  max-height: 63vh;
  overflow: scroll;
  padding-inline: 1rem;
  padding-bottom: 1rem;
  /* margin-top: 1rem; */
}
.chatHistory {
  /* width: 20rem; */
  /* max-height: 25vh; */
  /* height: 28vh; */
  /* overflow-y: auto; */
  overflow-x: hidden;
  padding-right: 0.3rem;
  margin-top: 0.4rem;
  /* margin-bottom: 2rem; */
}

.chatHistory_item {
  height: 2rem;
  padding-inline: 0.857rem;
  position: relative;
  line-height: 1rem;
  cursor: pointer;
  white-space: nowrap;
  /* overflow-x: hidden; */
  text-overflow: ellipsis;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1rem;
  border-radius: 5px;
  /* background: var(--xlight-grey); */
}

.chatItem-primary {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chatItem-primary span {
  margin-left: 7px;
}

.mind-link {
  width: 100%;
}



/* .chatHistory_item:hover, */
.chatHistory_item.active {
  background: var(--xlight-grey);
}

.chatHistory_buttons {
  display: none;
}

.chatHistory_item:hover .chatHistory_buttons {
  display: flex !important;
}

.chatHistory_item:hover .chat-title {
  max-width: 70%;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.chatHistory-btn {
  width: 1.5rem;
  height: 2rem;
  line-height: 2.25rem;
  text-align: center;
  transition: 0.2s;
}

.chatHistory-btn:hover {
  color: var(--secondary-color);
}

.chatHistory-btn i {
  font-size: 1rem;
}

.edit-title {
  width: 80% !important;
  background-color: #f6f6f6 !important;
  border: 1px solid grey;
}

.dropdown-menu h3 {
  padding: 1rem 1rem 1rem 1rem;
  line-height: 1rem;
  font-size: 1rem;
  margin-bottom: 0;
  font-weight: 500;
}

.resource-chats-heading {
  color: #76828b;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  /* line-height: 1rem; */
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-top: 10px;
  margin-bottom: 0;
  /* margin-inline: 10px; */
}

/********************************
PERSONA CHAT ACTIONS
********************************/
#chat-rows::-webkit-scrollbar {
  display: none !important;
}

/* .accordion-container::-webkit-scrollbar {
  display: none !important;
} */

.chat-row {
  background: #fff;
  margin-bottom: 1rem;
  position: relative;
  padding-right: 1rem;
}

.chat-rows {
  overflow: hidden;
  margin: 0 auto;
  padding: 0 1rem;
  width: 100%;
  max-width: 56rem;
  flex-grow: 1;
}

@media only screen and (max-width: 900px) {
  .chat-rows {
    padding: 0 .5rem 0 1rem;
  }
}

.chat-scroll {
  height: calc(100% - 1.5rem);
  overflow: scroll;
  padding-bottom: 5rem;
  margin-top: 1.5rem;
}

.accordion-container {
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  padding-bottom: 0.6rem;
}

.user-name {
  color: #76828b;
  font-weight: 300;
  font-size: 1rem;
  max-width: 100%;
  width: 100%;
  padding-top: 0.125rem;
  font-weight: 400;
}

.chatResponseContainer {
  display: flex;
  padding: 0.3rem 0;
  align-items: flex-start;
}

.chatResponse p:last-of-type {
  margin-bottom: 0;
}

.chatResponse p:empty {
  display: none;
}

.chatResponse a {
  text-decoration: none;
  color: var(--primary-color);
}

.chatResponse a:hover {
  text-underline-offset: 0.313rem;
  text-decoration: underline;
  text-decoration-color: var(--light-grey);
}

.chat-row .avatar {
  flex: 0 0 2rem;
  border-radius: 50%;
  margin-right: 6px;
  width: 2rem;
  height: 2rem;
}

.newChatBtn {
  /* margin-inline: 1rem; */
  padding: 0.5rem 1rem;
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  font-weight: 500;
  align-items: center;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  border-radius: 0.286rem;
  font-size: 1rem;
  /* cursor: not-allowed; */
}

.newChatBtn:hover {
  color: var(--primary-color);
}

/********************************
PERSONA CHAT ACTIONS
********************************/
.chat-row-action-buttons {
  display: flex;
  align-items: center;
}

.lower-buttons {
  padding-top: 1.5rem;
  gap: 0.25rem;
  justify-content: space-between;
  display: flex;
  align-items: center;
  width: 100%;
}

.evaluation-button {
  cursor: pointer;
}

.chat-row-action-buttons i {
  font-size: 1.25rem;
  padding: 0.25rem;
  cursor: pointer;
}

.chat-row-action-buttons i.disabled {
  cursor: default;
  opacity: 0.2;
}

.chat-row .icon-mind {
  color: var(--secondary-color);
  cursor: pointer;
  font-size: 1.125rem;
  /* position: absolute; */
  /* bottom: 0.25rem; */
  /* right: 0.25rem; */
}




.send-btn {
  position: absolute;
  right: 1.5rem;
  bottom: 1.7rem;
}

.hide-desktop {
  display: none;
}

@media only screen and (max-width: 900px) {
  .hide-desktop {
    display: block;
  }

  .send-btn {
    position: absolute;
    right: 1rem;
    bottom: .6rem;
  }
}


.chat-send {
  cursor: pointer;
  width: 2.3rem;
  height: 2.3rem;
  /* background-color: #4775D5; */
  border-radius: 100%;
  margin-left: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.disable-chat-button {
  opacity: 0.5;
  cursor: not-allowed;
  width: 2.3rem;
  height: 2.3rem;
  /* background-color: #87b19d; */
  cursor: not-allowed;
  /* border-radius: 100%; */
  margin-left: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sendBtnImg {
  width: 1.3rem;
  height: 1.3rem;
}

.chat-send:hover {
  cursor: pointer;
}

.chat-tab .edit-img {
  width: 30px;
}

.chat-tab .mind-img img {
  width: 29px;
  height: 29px;
}

.chat-tab .brendan-img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.chat-tab .trash-img {
  width: 30px;
}

.chat-input input {
  padding-right: 50px !important;
}

.chat-input-loader {
  height: 35px;
  /* position: absolute; */
  right: 0.571rem;
  bottom: 0.857rem;
  width: 35px;
}
.project-title{
  color: #051D2C;
  font-weight: 500;
  white-space: nowrap;
  max-width: 10rem;
  text-overflow: ellipsis;
  overflow: hidden;
}
.project-item:hover .project-title {
  max-width: 7rem !important;
}
.chat-title {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.3;
  font-size: 1rem;
  color: var(--primary-color);
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  /* line-height: 1rem; */
}

.chat-title-del {
  width: 70%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.3;
  font-size: 1rem;
}

.cross:hover {
  color: red !important;
}

.tick:hover {
  color: rgb(51, 136, 51) !important;
}

.icon-copy {
  margin-right: 3px;
}

/* typewriter effect */

.typed {
  line-height: 35px;
  width: 80%;
  text-align: left;
  /* font-family: "Courier New", "Lucida Console", monospace; */
  vertical-align: text-bottom;
}

.typed::after {
  content: "|";
  font-size: 150%;
  line-height: 30px;
  animation: blink 0.75s step-end infinite;
}

/* custom text area */
.custom-textarea {
  resize: none;
  border: 1px solid var(--border-color);
  width: 100%;
  border-radius: 5px;
  padding: 1rem;
  max-height: 22em;
  line-height: 1.5;
  overflow: hidden;
}

/* .custom-textarea[contenteditable]:empty::before {
  content: "Placeholder still possible";
  color: gray;
} */
@keyframes blink {

  from,
  to {
    color: transparent;
  }

  50% {
    color: rgba(245, 245, 245);
  }
}

.tooltip-button[aria-describedby]::before {
  font-size: 1.5rem !important;
  /* Adjust the font size as needed */
  /* Add other CSS styles here */
}

.chatNav-item {
  font-size: 1rem;
}
@media only screen and (max-width: 960px) {
  .custom-textarea {
    border: 0;
    border-top: 1px solid var(--border-color);
    border-radius: 0px;
  }
}







/* DRAWER TEMPORARY */
#drawer,
#drawer-toggle-label {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  /* adds animation for all transitions */
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
  margin: 0;
  padding: 0;
  -webkit-text-size-adjust: none;
}


#drawer-toggle {
  position: absolute;
  opacity: 0;
  z-index: 9999
}

#drawer-toggle-label {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  left: 0px;
  height: 4rem;
  width: 3rem;
  display: block;
  position: fixed;
  background: rgba(255, 255, 255, .0);
  z-index: 1060;
  top: 0;
  cursor: pointer;
}

@media only screen and (min-width: 960px) {
  #drawer-toggle-label {
    display: none;
  }
}

/* adds our "hamburger" menu icon */

#drawer-toggle-label:before {
  content: '';
  display: block;
  position: absolute;
  height: 2px;
  width: 20px;
  background: #333;
  left: 13px;
  top: 20px;
  box-shadow: 0 6px 0 #333, 0 12px 0 #333;
}

/* drawer menu pane - note the 0px width */

.left-sidebar .chatHistory_item {
  width: 100%;
  align-items: center;
  display: flex;
  /* height: 45px; */
}

#drawer {
  position: fixed;
  top: 4rem;
  left: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 4rem);
  flex: 0 0 16rem;
  width: 16rem;
  border-right: 1px solid var(--border-color);
}

@media only screen and (max-width: 960px) {
  #drawer {
    position: fixed;
    z-index: 999;
    top: 0;
    left: -16rem;
    height: 100dvh;
  }
}

/* checked styles (menu open state) */

#drawer-toggle:checked~#drawer-toggle-label {
  height: 100%;
  width: calc(100% - 16rem);
  background: rgba(255, 255, 255, .8);
}

#drawer-toggle:checked~#drawer-toggle-label {
  left: 16rem;
}

#drawer-toggle:checked~#drawer {
  left: 0;
}

.menuButton {
  display: flex;
  align-items: center;
  grid-gap: 0.429rem;
  cursor: pointer;
  background: #fff;
  padding: .5rem 0 0 0;
  background: #fff;
  width: 100%;
  font-weight: 600;
}

.UpdatePrompt {
  font-size: 0.875rem;
  line-height: 1em;
  text-align: right;
  padding: 0.5rem 1rem 0 1rem;
  cursor: pointer;
  max-width: 56rem;
  margin: 0 auto;
}

@media only screen and (max-width: 960px) {
  .UpdatePrompt {
    padding: 0.5rem;
  }
}
@media only screen and (max-width: 1400px) {
  .accordion-container {
    max-height: 65vh;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    padding-bottom: 0.6rem;
  }
}
