@font-face {
  font-family: 'icomoon';
  src:  url('icomoon.eot?dwabhi');
  src:  url('icomoon.eot?dwabhi#iefix') format('embedded-opentype'),
    url('icomoon.ttf?dwabhi') format('truetype'),
    url('icomoon.woff?dwabhi') format('woff'),
    url('icomoon.svg?dwabhi#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-thumbs-down:before {
  content: "\e91d";
}
.icon-thumbs-up:before {
  content: "\e92c";
}
.icon-copy:before {
  content: "\e900";
}
.icon-chevron-right:before {
  content: "\e901";
}
.icon-chevron-left:before {
  content: "\e902";
}
.icon-chevron-down:before {
  content: "\e903";
}
.icon-chevron-up:before {
  content: "\e904";
}
.icon-file-minus:before {
  content: "\e905";
}
.icon-minus-circle:before {
  content: "\e906";
}
.icon-user-minus:before {
  content: "\e907";
}
.icon-folder-minus:before {
  content: "\e908";
}
.icon-file-plus:before {
  content: "\e909";
}
.icon-plus-square:before {
  content: "\e90a";
}
.icon-plus-circle:before {
  content: "\e90b";
}
.icon-plus:before {
  content: "\e90c";
}
.icon-user-plus:before {
  content: "\e90d";
}
.icon-folder-plus:before {
  content: "\e90e";
}
.icon-external-link:before {
  content: "\e90f";
}
.icon-share-2:before {
  content: "\e910";
}
.icon-search:before {
  content: "\e911";
}
.icon-triangle-up:before {
  content: "\e912";
}
.icon-triangle-right:before {
  content: "\e913";
}
.icon-triangle-left:before {
  content: "\e914";
}
.icon-triangle-down:before {
  content: "\e915";
}
.icon-settings:before {
  content: "\e916";
}
.icon-dislike:before {
  content: "\e917";
}
.icon-more-vertical:before {
  content: "\e918";
}
.icon-more-horizontal:before {
  content: "\e919";
}
.icon-eye:before {
  content: "\e91a";
}
.icon-like:before {
  content: "\e91b";
}
.icon-send:before {
  content: "\e91c";
}
.icon-chat:before {
  content: "\e91e";
}
.icon-mind:before {
  content: "\e91f";
}
.icon-moment:before {
  content: "\e920";
}
.icon-user:before {
  content: "\e921";
}
.icon-alert-triangle:before {
  content: "\e922";
}
.icon-download-cloud:before {
  content: "\e923";
}
.icon-download:before {
  content: "\e924";
}
.icon-upload-cloud:before {
  content: "\e925";
}
.icon-upload:before {
  content: "\e926";
}
.icon-edit:before {
  content: "\e927";
}
.icon-edit-2:before {
  content: "\e928";
}
.icon-trash-2:before {
  content: "\e929";
}
.icon-trash:before {
  content: "\e92a";
}
.icon-calendar:before {
  content: "\e92b";
}
