.buGObk {
  border-bottom-color: #3c3c46 !important;
  min-height: 40px !important;
  border-bottom-width: 2px !important;
}
.VqMBs {
  padding-bottom: 10px !important;
}
.css-1nur6r0 {
  border: 1.7px solid #051D2C !important;
}
.hZHhrg{
  min-height: 210px !important;
}
/* usertable.css */

.table-container {
  max-width: 80vw;
  overflow-x: auto;
}
.assign-owner h5 {
  color:#051D2C;
  font-size: 1.3rem;
  font-weight: 400;
  margin-bottom: 0;
}
.assign-owner p {
  color:#051D2C;
  font-size: 0.9rem;
  font-weight: 400;
}
/* mobile devices */
/* usertable.css */

@media (max-width: 500px) {
  .table-container {
    max-width: 90vw;
    overflow-x: auto;
  }
}

@media (min-width: 500px) and (max-width: 767px) {
  .table-container {
    max-width: 90vw;
    overflow-x: auto;
  }
}
@media (max-width: 1200px) {
  .table-container {
    max-width: 90vw;
    overflow-x: auto;
  }
}
/* ant table styling */

.ant-table-thead > tr > th {
  background-color: transparent !important;
}
.ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before,
:where(.css-dev-only-do-not-override-190m0jy).ant-table-wrapper
  .ant-table-thead
  > tr
  > td:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  display: none !important;
}

.table-container div[data-column-id='2']{
  min-width: 300px;
}
.table-container div[data-column-id='6']{
  max-width: 70px;
  min-width: 50px;
}
@media only screen and (max-width: 1399px) {
  .table-container .rdt_TableRow{
    font-size: 1rem;
  }
  .table-container div[data-column-id='1']{
    min-width: 200px;
  }
}