.userprofile-container {
    position: relative;
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 2rem;
}

.close-icon {
    cursor: pointer;
    position: absolute;
    top: 1.3rem;
    right: 1.2rem;
    font-size: 1.3rem;
}

.userprofile {
    width: 60%;
    border-radius: 12px;
    border: 1px solid #CED3D9;
    background: #FFF;
    padding: 1.714rem;
    min-height: 600px;
}

.userprofile h1 {
    margin-bottom: 1rem;
}

.upload-desc {
    font-size: 1rem;
    color: #3C3C46;
}

.upload-heading {
    margin-bottom: 2px !important;
    font-size: 1em;
    font-weight: 600;
}

/* subscriptions styles */
.subscription-container {
    /* width: 80%; */
}

.subscription-container h1 {
    font-size: 2.143rem;
    font-weight: 500;
}

.renewal-amount {
    font-size: 2.5rem;
    color: #4775D5;
}

.renewal-amount span {
    text-decoration: line-through;
    text-decoration-color: #4775D5;
    color: #76828B;
    font-size: 1.429rem;
    font-weight: 400;
}
.chakra-tabs>div>button[aria-selected=true]{
    color: #4775D5 !important;
}
@media only screen and (max-width: 1199px) {
    .subscription-container h1{
        font-size: 1.5rem;
    }
}
@media only screen and (max-width: 960px) {
    .userprofile {
        width: 100%;
        padding: 1rem;
    }
    .close-icon{
        top: 0;
        right: 0;
    }
}
@media only screen and (max-width: 768px) {
  .userprofile  div[role="tablist"] button{
    font-size: 0.714rem;
    }
    .userprofile-container{
        padding: 0;
    }
    .close-icon{
        top: 0.357rem;
        right: 0.357rem;
    }
    .close-icon svg{
        width: 1.429rem;
        height: 1.429rem;
    }
    .form-container>div{
        flex-direction: column;
    }
    .form-container>div .form-field{
        margin-left: 0 !important;
    }
   
}