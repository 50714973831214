  @import url("https://use.typekit.net/ceh2jwg.css");
  @import url('https://fonts.googleapis.com/css2?family=Inter+Tight:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

  * {
    font-family: 'Inter', sans-serif;
  }

  *:focus {
    outline: none !important;
    box-shadow: none !important;
  }

  :root {
    --background-color: #F9F9F9;
    --border-color: #CED3D9;
    --inputBorder-color: #051D2C;
    --primaryGreen-color: #4775D5;
    --bgLight-color: #FFFFFF;
    --bgDark-color: #051D2C;

    --text-color: #051D2C;
    --white-color: #fff;
    --grey-text: #A6ABAF;
    --borders: #CED3D9;
    --darker-grey: #69757E;
    --medium-grey: #76828B;
    --background-grey: #F3F4F5;

    --primary-font: 'Inter', sans-serif;

    --primary-color: #051D2C;
    --secondary-color: #4775D5;
    --secondary-color-N: #AF144B;
    --bs-link-color: red;
    --xlight-grey: #f6f6f6;
    --light-grey: #F5F6F7;
    --medium-grey: #a09fa1;
    --dark-grey: #232529;
    --spacing-xsmall: 0.5rem;
    --spacing-small: 1rem;
    --spacing-medium: 1.5rem;
    --spacing-large: 2.5rem;

    --chakra-fonts-heading: 'Inter', sans-serif;
  }

  body,
  html {
    font-size: 14px !important;
    color: var(--text-color);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Inter Tight', sans-serif !important;
    font-weight: 600 !important;

  }

  /* body{
  background: var(--background-color) !important;
} */
  .bg-white {
    background-color: #fff !important;
  }

  .bg-black {
    background-color: var(--bgDark-color) !important;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .color-darkGray {
    color: var(--inputBorder-color);
  }

  .mt-80 {
    margin-top: 70px;
  }

  div[role="tablist"] button {
    color: #1D1E24;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background: #fff;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: var(--primary-color);
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--primary-color);
  }

  .overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99999999 !important;
    background: radial-gradient(rgba(20, 20, 20, 0.4), rgba(0, 0, 0, 0.4));
  }

  .loaderImage-container {
    position: absolute !important;
    left: 50% !important;
    top: 50% !important;
    transform: translateX(-50%);
  }

  .non-clickable {
    pointer-events: none;
  }



  /* dark mode */
  .persona-container-dark {
    background-color: black;
    min-height: 100vh;
    padding-top: 4rem;
  }

  .persona-heading-dark {
    color: white;
  }

  .modal-close-btn {
    color: white !important;
  }

  /* light mode */
  .persona-container-light {
    background-color: #fff;
    padding-top: 4rem;
  }





  /* SPLIT PAGE */
  .mainContainer {
    display: flex;
    min-height: calc(100vh - 7.875rem);
  }


  .mainContainer-right {
    flex: 1;
    padding: 0;
    padding-left: 16rem;

  }

  .mainContainer-Inner {
    padding: 1.5rem;
    flex: 1;
  }

  @media (max-width: 960px) {
    .mainContainer-right {
      padding-left: 0;
    }

    .mainContainer-Inner {
      padding: 1rem;
      padding-left: 1rem;
    }
  }





  /* FORMS */
  .form-field {
    margin-bottom: 1rem;
    width: 100%;
  }

  .form-control {
    width: 100%;
    padding: 0.375rem 0.75rem;
    line-height: 1;
    color: var(--primary-color);
    border: 2px solid var(--primary-color);
    border-radius: 0.375rem;
  }

  .form-control:hover,
  .form-control:focus {
    border: 2px solid var(--primary-color);
  }

  /* BUTTONS */
  .primaryButton {
    border-radius: 5px;
    border: 1px solid #051D2C;
  }

  .btn {
    border-radius: 0.375rem;
  }

  .btn-primary {
    padding: 0.5rem 1rem;
    background-color: var(--primary-color);
    border-color: var(--primary-color);
  }

  .btn-primary:focus,
  .btn-primary:hover {
    background-color: #333;
    border-color: #333;
    color: #fff
  }

  .btn-outline-primary {
    padding: 0.5rem 1rem;
    border-color: var(--primary-color);
  }

  .btn-outline-primary:focus,
  .btn-outline-primary:hover {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
  }

  .dropdown-menu {
    border: 1px solid var(--border-color)
  }

  .btn-group .btn {
    padding: 0.375rem 1rem;
  }

  .dropdown-menu {
    padding: 0
  }

  .dropdown-item {
    padding: 0.625rem 1rem;
    cursor: pointer;
  }

  .dropdown-item.disabled {
    cursor: default;
  }

  .dropdown-item:hover {
    background-color: #f5f6f7;

  }

  .dropdown-item.active,
  .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #f5f6f7;
    color: var(--primary-color);
  }

  .btn-primary:disabled,
  .btn-primary.disabled {
    color: #fff;
    background-color: #999;
    border-color: #999;
  }

  .form-label {
    margin-bottom: 0.25rem;
  }

  h1 {
    font-size: 1.5rem;
    margin-top: 0;
    margin-bottom: 0;
  }

  .swal2-title {
    /* font-size: 1.4rem !important; */
    color: black !important;
    font-weight: 400 !important;
  }

  .swal2-styled.swal2-confirm {
    background-color: black !important;
    /* Change to your desired background color */
    color: white !important;
    /* Change to your desired text color */
    border: 1px solid black !important;
    /* Remove the default button border */
    border-radius: 5px !important;
    padding: 0.571rem 1.143rem !important;
    font-weight: 300 !important;
  }

  .swal2-styled.swal2-confirm:hover {
    background: black !important;
  }

  .disable-btn {
    cursor: not-allowed !important;
  }