.massive-alliance-container {
  width: 100%;
}

.massive-alliance-inner {
  max-width: 1000px;
  margin: 0 auto;
  padding-block: 50px;
  padding-inline: 2rem;
  display: flex;
  align-items: center;
  gap: 40px;
  width: 100%;
}

.massive-features {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 50%;
  gap: 1.5rem;
}

.brand-guide-box {
  border-radius: var(--none, 0px);
  border: 1px solid #f5f6f7;
  background: #f5f6f7;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 190px;
  height: 150px;
}

.box-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.286rem;
}

.brand-guide-box img,
.coming-soon-box img {
  width: 50px;
  height: 62px;
}

.brand-guide-box p,
.coming-soon-box p {
  color: #051d2c;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
}

.coming-soon-box {
  border-radius: var(--none, 0px);
  border: 1px solid rgba(155, 165, 171, 0.25);
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 190px;
  height: 150px;
}

.newproject-form {
  width: 50%;
}

.newproject-form p {
  color: #051d2c;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
}

.search-chip-box {
  display: flex;
  width: 100%;
  padding: 1.714rem 2.286rem;
  padding-bottom: 1.143rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-bottom: 1px solid #ced3d9;
}

.search-chip-box .input-calender {
  background: #fff;
  padding: 1.429rem;
  border: 1px solid #ced3d9;
  border-left: unset;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
}
.chips-container.brnad-chips .MuiChip-sizeMedium{
  margin-bottom: 0;
}
.search-chip-box .icon-pair {
  background: #fff;
  border: 1px solid #ced3d9;
  border-radius: 4px;
}

.search-chip-box .icon-child {
  padding: 1.429rem;
  cursor: pointer;
}

.search-chip-box .icon-child:first-child {
  border-right: 1px solid #ced3d9;
}

.brand-guid-blocks {
  margin-top: 1.429rem;
  padding: 2.286rem;
  background: #f5f6f7;
}

.brand-guid-blocks .download-btn {
  border-radius: 5px;
  border: 1px solid #051d2c;
  background: #051d2c;
  color: #fff;
  /* width: 143px; */
  height: 42px;
  padding: 0.857rem 1.429rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.brand-guid-blocks .content-block {
  border-radius: 0.857rem;
  border: 1px solid #ddd;

  background: #fff;
}

.brand-guid-blocks .content-block>h2 {
  padding: 1.714rem;
}

.circle-item {
  height: 1.714rem;
  width: 1.714rem;
  font-size: 0.857rem;
  font-style: normal;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: #eff1f1;
}
.delete-icon-active{
  display: none;
  }
  .delete-icon:hover .delete-icon-notActive{
    display: none;
  }
.delete-icon:hover .delete-icon-active{
display: block;
}
.delete-icon:hover{
  background-color: #fef3f3;
  border-color:#E80E0E ;
}
.brand-guid-blocks .content-block .block-title {
  font-size: 1.143rem;
  font-style: normal;
  font-weight: 350;
  line-height: 1.143rem;
}

.brand-guid-blocks .content-block figure {
  width: 100%;
}

.brand-guid-blocks .content-block figure img {
  height: 14.286rem;
  width: 14.286rem;
  border-radius: 100%;
  object-fit: cover;
}

.brand-guid-blocks .content-block .contentWraper {
  display: flex;
  flex-wrap: wrap;
  gap: 1.714rem;
  justify-content: space-between;
}

.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused),
.ck.ck-editor__main>.ck-editor__editable ,.rdw-editor-main{
  border-radius: 0 0 10px 10px !important;
  border-width: 1px;
}
.rdw-editor-main{
padding:0 10px !important;
}
.rdw-option-wrapper,.rdw-dropdown-wrapper{
  border: unset !important;
}
.rdw-option-wrapper:hover,.rdw-dropdown-wrapper:hover{
  box-shadow: unset !important;
}
.ck.ck-toolbar.ck-toolbar_grouping,
.rdw-editor-toolbar {
  border-radius: 10px 10px 0 0 !important;
  margin-bottom: 0 !important;
}

.chat-footer {
  padding: 2.286rem 2.286rem 1.357rem 2.286rem;
}

.chat-footer figure {
  width: fit-content;
  margin-bottom: 0;
}
.brand-genration-step{
  margin-top: 1.429rem;
  padding: 2.286rem;
}
.genration-inner-block{
  align-items: center;
  display: flex;
  background: rgba(71,117,213,.05);
    border-radius: 0.571rem;
    flex-direction: column;
    justify-content: center;
    min-height: 30vh;
    padding-block: 1.5rem;
    width: 100%;
}

.search-chip-box .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl{
  border-radius: 4px 0px 0px 4px;
}
.chat-footer {
  position: relative;
}
.chat_popup{
  position: fixed;
    bottom: 5rem;
    border-radius: var(--1, 8px);
    background: #FFF;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.14);
    /* padding: 24px; */
    width: 500px;
    min-height: 55vh;
    z-index: 1;
}
.chat_popup .chat-input{
  position: absolute;
    width: 100%;
    left: 0;
}
.chat_popup .chat-scroll{
  max-height: 70vh;
}
.heading-style{
  color:red
}
@media only screen and (max-width: 1399px) {
  .search-chip-box .input-calender {
    padding: 0.857rem;
    height: 2.714rem;
  }

  .search-chip-box .icon-child {
    padding: 0.857rem;
    height: 2.714rem;
  }

  .brand-guid-blocks .content-block figure img {
    height: 8.571rem;
    width: 8.571rem;
  }
}
@media only screen and (max-width: 1199px) {
  .brand-guide-box,.coming-soon-box {
   
    width: 100%;
    height: 150px;
  }
}
@media only screen and (max-width: 768px) {
  .massive-alliance-inner{
    flex-direction: column-reverse;
  }
  .newproject-form,.massive-features{
    width: 100%;
  }
  .chat_popup{
    width: 96%;
    right: 2%;
  }
  .brand-guid-blocks{
    padding:1.3rem;
  }
  .search-chip-box{
    padding: 1.3rem;
  }
}