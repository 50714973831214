.client-profile .tab-names button{
    font-weight:  500;
    z-index:2
}


.client-profile {
    max-width:60rem;
}
.client-profile h1 {
    text-align: left;
    margin-bottom:2rem;
    margin-top:0;
}