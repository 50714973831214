.pageHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: 1.5rem;
}

.persona-container {
  max-width: 100%;
  margin: 0 auto;
  padding: var(--spacing-medium);
}

#file-upload,
#img-upload {
  display: none;
}

.personaUpload-document {
  padding: 2rem;
  border-radius: 0.25rem;
  border: 1px dashed #ccc;
  background: #f6f6f6;
}

.create-persona-container {
  width: 100%;
}

.inner-wrapper {
  width: 50%;
}

.personaForm {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 1rem;
  column-gap: 1.3rem;
  width: 100%;
}

.create-persona-input {
  height: 3.214rem;
  width: 100%;
  border: 1.5px solid #1d1e24;
  border-radius: 5px;
  padding-inline: 0.4rem;
}

.upload-image-container {
  width: 100%;
  font-family: var(--primary-font) !important;
}

@media only screen and (max-width: 1000px) {
  .inner-wrapper {
    width: 70%;
  }

  .dropdown-container {
    width: 90%;
  }
}

@media only screen and (min-width: 1200px) {
  .dropdown-container {
    width: 70%;
  }
}

@media only screen and (max-width: 767px) {
  .inner-wrapper {
    width: 100%;
  }

  .personaForm {
    grid-template-columns: 1fr;
  }

  .dropdown-container {
    width: 100%;
  }
}

.personaForm .full-width {
  grid-column: 1 / span 2;
}






/* Persona Team Table */
.person-team {
  width: 100%;
  border: 1px solid var(--border-color);
  border-radius: 0.25rem;
  overflow: hidden;
  margin-top: 2rem !important;
}

.person-team-header {
  background: #f6f6f6;
  display: flex;
  border-bottom: 1px solid var(--border-color);
}

.person-team-row {
  display: flex;
  border-bottom: 1px solid var(--border-color);

}

.person-team-row:last-child {
  border-bottom: 0;
}

.column {
  flex-grow: 1;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
}

.column p {
  margin-bottom: 0;
}

.column .brook-img,
.column .chakra-image {
  flex: 0 0 2rem;
  margin-right: 0.5rem;
}

.columnRemove {
  flex: 0 0 80px
}

.columnDate {
  flex: 0 0 13.571rem
}

.columnType {
  flex: 0 0 20%
}

.columnInvitedBy {
  flex: 0 0 20%
}

/* .overlay {
  display: none
} */

@media only screen and (max-width: 767px) {
  .person-team-row {
    flex-direction: column;
    border-bottom: 1px solid var(--border-color);

  }
}

.plusCircle {
  width: 1.5rem;
}

.emissary-list {
  border: 1px solid #CED3D9;
  border-radius: 0.857rem;
  overflow: hidden;
  overflow-x: auto;
}

.emissary-list th {
  background: #FAFAFB;
  border-bottom-color: #CED3D9 !important;
  color: #76828B;
  /* font-family: "Normalidad"; */
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.emissary-list table td {
  font-size: 1.143rem;
  font-weight: 300;
}

.emissary-list table tr {
  cursor: pointer;
}

.emissary-list td,
.emissary-list th {
  padding: 1.286rem 1.429rem;
  vertical-align: middle;
}

.emissary-list table td img {
  width: 3.071rem;
  height: 3.071rem;
  border-radius: 100%;
  object-fit: cover;
  object-position: center;
}

.emissary-list table .persona-name {
  align-items: baseline;
  margin: 0;
}

.emissary-list table .persona-name h3 {
  font-size: 1.143rem;
  font-weight: 500;
  margin-top: 0;
  white-space: nowrap;
}

.emissary-list table .persona-name p {
  font-size: 1rem;
  font-weight: 300;
  margin-bottom: 0;
  white-space: nowrap;
}

.emissary-list table .grid-project-container {
  display: flex;
  gap: 0.429rem;
  height: 3.214rem;
  align-items: center;
}

.emissary-list table .grid-project-container span {
  background: #f9f9f9;
  color: #051d2c;
  border-radius: 3.571rem;
  padding: 0 0.857rem;
  height: 1.786rem;
  font-size: 0.929rem;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  min-width: 5rem;
}

.persona-action-buttons-table {
  display: flex;
  gap: 1.429rem;
  align-items: baseline;
}


/* / / edit persona styling / / */
.edit-top-section {
  display: flex;
  padding: 1.714rem;
  flex-direction: column;
  align-items: center;
  gap: 1.429rem;
  border-radius: 0.571rem;
  background: #f6f8fd;
  width: 100%;
}

.edit-top-section img {
  width: 9.143rem;
  height: 9.143rem;
}

.edit-top-section h3 {
  color: #051d2c;
  text-align: center;
  margin-bottom: 1px;
  font-size: 2.143rem;
  font-weight: 500;
}

.edit-top-section p {
  color: #76828B;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 350;
}

@media (max-width: 1399px) {
  .edit-top-section {
    display: flex;
    padding: 1.286rem;
    flex-direction: column;
    align-items: center;
    gap: 1.143rem;
    border-radius: 0.571rem;
    background: #f6f8fd;
    width: 100%;
  }

  .edit-top-section img {
    width: 6.429rem;
    height: 6.429rem;
  }

  .edit-top-section h3 {
    color: #051d2c;
    text-align: center;
    font-size: 1.429rem;
    font-weight: 500;
    margin-bottom: 1px;
  }

  .edit-top-section p {
    color: #76828B;
    text-align: center;
    font-size: 0.857rem;
    font-style: normal;
    font-weight: 350;
  }
}


@media (max-width: 1199px) {
  .edit-top-section {
    display: flex;
    padding: 1.286rem;
    flex-direction: column;
    align-items: center;
    gap: 1.143rem;
    border-radius: 0.571rem;
    background: #f6f8fd;
    width: 100%;
  }

  .edit-top-section img {
    width: 6.429rem;
    height: 6.429rem;
  }

  .edit-top-section h3 {
    color: #051d2c;
    text-align: center;
    font-size: 1.429rem;
    font-weight: 500;
    margin-bottom: 1px;
  }

  .edit-top-section p {
    color: #76828B;
    text-align: center;
    font-size: 0.857rem;
    font-style: normal;
    font-weight: 350;
  }
}

@media (max-width: 767px) {
  .edit-form-row {
    flex-direction: column;
  }

}

.create-persona-title {
  color: #051D2C;
  text-align: center;
  font-size: 1.571rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2.071rem;
  /* 131.818% */
  margin-bottom: 1.429rem;
}

#newPersonaModal .modal-dialog .modal-content {
  background: #FAFAFB;
}

.create-persona-container .inner-wrapper {
  background: #fff;
  padding: 1.714rem;
  border-radius: 0.857;
}

.create-persona-container .personaForm {
  margin-bottom: 2.857rem;
  margin-top: 2.857rem;
}

.upload-image-container {
  display: flex;
  padding: 1.714rem;
  flex-direction: column;
  align-items: center;
  gap: 1.429rem;
  border-radius: 8px;
  background: rgba(71, 117, 213, 0.08);
  width: 100%;
}

.upload-image-container label {
  height: 9.143rem;
  width: 9.143rem;
}

.upload-image-container h1 {
  color: #051d2c;
  text-align: center;
  margin-bottom: 1px;
  font-size: 1.143rem;
  font-weight: 500;
}

.upload-image-container .upload-desc {
  color: #76828B;
  text-align: center;
  font-size: 1.071rem;
  font-style: normal;
  font-weight: 300;
}

.close-cross {
  position: absolute;
    top: 7px;
    right: 21px;
}