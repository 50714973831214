

.not-fixed-button, .bottom-right-button {
  border: none;
  cursor: pointer;
  height: 2.25rem;
  position: fixed;
  z-index: 1000;
  top: 9px;
  right: 4.5rem;
}
.not-fixed-button svg, .bottom-right-button svg {
  width:100%;
}